.page-spinner-container {
    padding: 100px;
}

.home-description {
    color: white;
    text-align: left;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 1em;
    padding: 5px;
}

.home-container {
    margin-bottom: 15%;
    color: white;
}

.sub-header {
    font-weight: bold;
    font-size: 1.4em;
    border-bottom: 1px solid white;
    display: inline-block;
}

.what-you-need-to-know {
    font-weight: bold;
}

.movie-cover {
    width: 10em;
    height: auto;
}

.movie-title {
    font-weight: bold;
    font-size: 1.2em;
    padding: 5px;
}

.movie-grid-item {
    padding: 10px;
}
