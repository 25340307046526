.header-wrapper {
    text-align: left;
    font-size: 1.2em;
    padding: 0.2em;
    color: white;
    font-family: 'Pacifico', cursive;
}

a {
    color: white;
    text-decoration: none;
}