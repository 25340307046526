.timeline-movie-title {
    padding-bottom: 5px;
    text-align: left;
    font-weight: bold;
}

.timeline-poster-wrapper {
    display:flex;
    align-items: center;
    float: left;
}

.timeline-movie-cover {
    width: 5em;
    height: auto;
}

.timeline-content-wrapper {
    float: right;
    width: 65%;
    font-size: 11px;
}

.subtimeline-year {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    padding-bottom: 5px;
}

.subtimeline-content {
    text-align: left;
}

.subtimeline-movie-title {
    text-align: left;
    position: absolute;
    bottom: 2px;
}

.timeline-face {
    width: 10px;
    height: auto;
}

.timeline-subheader {
    font-weight: bold;
    color: white;
    font-size: 1.4em;
    display: inline-block;
    margin-bottom: 15px;
}

.timeline-subheader-text {
    border-bottom: 1px solid white;
}

.timeline-by-header {
    font-size: 0.6em;
    text-align: left;
}

.edit-button-container {
    float: right;
}

.event-modal-container {
    background-color: white;
    margin: 20px;
    position: fixed;
    width: 75%;
}

.input-container {
    padding: 2px;
}

input,textarea,select {
    border: none;
    border-bottom: 1px solid rgb(33, 150, 243);
    width: 80%;
}

input:focus, textarea:focus, select:focus{
    outline: none;
}

.save-button-container {
    padding: 5px;
}

.suggested-title:hover {
    color: white;
    background-color: rgb(33, 150, 243);
}

.suggested-title {
    font-size: 11px;
    border-bottom: 1px solid darkgray;
}

.error-container {
    background-color: #fbcccc;
    border: 1px solid red;
    color: red;
    font-size: 11px;
    padding: 5px;
    margin: 5px;
}